@font-face {
    font-family: Manrope;
    font-weight: 400;
    font-style: normal;
    src: url('./Manrope-Regular.ttf');
  }
  @font-face {
    font-family: Manrope;
    font-weight: 500;
    font-style: normal;
    src: url('./Manrope-Medium.ttf');
  }
  @font-face {
    font-family: Manrope;
    font-weight: 600;
    font-style: normal;
    src: url('./Manrope-SemiBold.ttf');
  }
  @font-face {
    font-family: Manrope;
    font-weight: 700;
    font-style: normal;
    src: url('./Manrope-Bold.ttf');
  }
  