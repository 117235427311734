@import url('./assets/fonts/Manrope/stylesheet.css');
// FONTS
@import url('./assets/fonts/songer-semi-expanded/stylesheet.css');



::-webkit-scrollbar {
    display: none;
}

video::-webkit-media-controls {
  display: none !important;
}

/*Обнуление*/
* {
    padding: 0;
    margin: 0;
    border: 0;
}
*, *:before, *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
:focus, :active { outline: none; }
a:focus, a:active { outline: none; }

nav, footer, header, aside { display: block; }

html, body {
    background: #352F2A;
    height: 100%;
    width: 100%;
    font-size: 100%;
    line-height: 1;
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    // scroll-behavior: smooth;
}
input, button, textarea { font-family: inherit; }

input::-ms-clear { display: none; }
button { cursor: pointer; border: none; }
button::-moz-focus-inner { padding: 0; border: 0; }
a, a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
ul li { list-style: none; }
img { vertical-align: top; }

h1, h2, h3, h4, h5, h6 { font-size: inherit; font-weight: 400; }
/*--------------------*/

/* Клас для блокування прокручування сторінки */
.no-scroll {
  overflow: hidden;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.bold-text {
  color: #352F2A;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .bold-text {
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .bold-text {
    font-size: 18px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1199px) {
  .bold-text {
    font-size: 20px;  
  }
  
}

@media screen and (min-width: 320px) and (max-width: 799px) {
  .bold-text {
    font-size: 13px;
  }
}